.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #0c55d2;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom: 10px;
}

/* Improved styling for links */
.bm-item a {
  text-decoration: none;
  color: #ffffff; /* Change to your desired color */
  font-size: 1.2em;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.bm-item a:hover {
  color: #0c55d2; /* Change to your desired hover color */
}

/* Styling for the title */
.bm-title {
  font-family: 'YourCoolFont', sans-serif; /* Replace 'YourCoolFont' with the desired font name */
  font-size: 1.5em;
  font-weight: bold;
  color: #ffffff; /* Change to your desired color */
  margin-bottom: 15px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Add this to your existing CSS file or create a new one */

/* Center the container div */
.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the container takes up the full height of the Menu */
}

.invite-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.menu-button-container {
  display: flex;
  justify-content: center;
}

.menu-content {
  flex: 1;
}

.invite-button:hover {
  background-color: #45a049;
}



.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Add this to your existing CSS or create a new one */
.search{
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 10px; */
  /* width: 100%; */
}

input {
  width: 100%;
}

.search-input {
  /* padding: 8px; */
  /* width: 100%; */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.grayed-background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: #373a47;
  opacity: 40%;
  z-index: 999;
}

.info-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  z-index: 1000;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

@media (max-width: 800px) {
  .info-card {
    width: 100vw;
    max-height: 100vh;
  }
}


.info-card h1 {
  margin: 0px;
}
.info-card h3,
.info-card p {
  padding: 0;
  margin-bottom: 10px; 
}

.info-card a {
  color: rgba(195, 0, 255, 0.644);
}

.info-card a:hover {
  color: rgba(195, 0, 255, 0.548);
}


.info-card button {
  margin: 0;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white; 
  border: none;
  border-radius: 5px;
  cursor: pointer; 
}

.info-card button:hover {
  background-color: #348338;
}